import React from 'react';
import SectionWrapper from '../../../components/SectionWrapper/SectionWrapper';
import WhyUsBG from '../../../assets/bg/why-us-bg.svg';
import SectionHeader from '../../../components/SectionHeader/SectionHeader';
import { TServices } from '../../../constants/types';
import ITServiceImage from '../../../assets/icons/main-services/IT_icon.png';
import MTServiceImage from '../../../assets/icons/main-services/MT_icon.png';
import routes from '../../../constants/routes';
import { ArrowLongRightIcon } from '@heroicons/react/24/outline';
import LinkButton from '../../../components/LinkButton/LinkButton';

const ServiceList: TServices[] = [
  {
    title: 'Elevated Precision',
    description:
      "Our transcription service doesn't just meet industry standards; we exceed them. With our two-level editing process (and the option to go up to three), we confidently deliver accuracy levels ranging from a solid 98% to a perfect 100%.",
    icon: MTServiceImage,
    url: routes.SERVICE_MT_PAGE,
  },
  {
    title: 'Seamless Integration',
    description:
      'Picture this – your medical records effortlessly flowing into your RIS/PACS and Practice Software. With Jivescribe, we make this integration a reality. We harmonize your data, ensuring a smooth operation between systems.',
    icon: ITServiceImage,
    url: routes.SERVICE_MT_PAGE,
  },
];

const ServicesCard: React.FC<TServices> = ({ icon, title, description, url }) => {
  return (
    <div className="whyus-card text-cente  relative mx-2 my-6   gap-y-5 rounded-3xl  border bg-transparent bg-opacity-75  p-10 shadow-lg max-xs:px-5  xs:mx-8 xs:min-h-[24rem] xs:gap-y-10">
      <img
        alt="jivescribe medical solutions"
        src={icon}
        className="absolute left-1/2 mx-auto w-40 -translate-x-1/2 -translate-y-3/4  transform max-xs:w-32 xs:w-52"
      />
      <div className="flex flex-col gap-5 pt-20 max-xs:pt-10">
        <h2 className=" text-center  font-bold text-default-blue ">{title}</h2>
        <div className="h-auto lg:h-40 xl:h-32">
          <p className=" text-description max-xs:wrap-balance text-description font-medium text-black">{description}</p>
        </div>

        <div className="flex justify-center">
          <LinkButton
            text="Learn More"
            color="secondary"
            variant="rounded"
            icon={<ArrowLongRightIcon className="h-4 xs:h-7" />}
            customClass="btn-icon-move-right"
            to={url ?? ''}
          />
        </div>
      </div>
    </div>
  );
};

const Services = () => {
  return (
    <SectionWrapper customClasses=" relative h-auto">
      <img
        alt="jivescribe why us"
        src={WhyUsBG}
        className="absolute  left-1/2 top-1/2 w-full -translate-x-1/2 -translate-y-1/2 transform"
      />
      <div className="relative mx-auto max-w-7xl">
        <SectionHeader title="Our Services" />

        <div className=" grid grid-cols-1 gap-y-10 pt-20 max-xs:pt-10 xs:gap-y-28 lg:grid-cols-2 lg:gap-y-0">
          {ServiceList.map((slide, index) => {
            return (
              <div className="col-span-1 " key={index}>
                <ServicesCard {...slide}></ServicesCard>
              </div>
            );
          })}
        </div>
      </div>
    </SectionWrapper>
  );
};

export default Services;
