import React, { useEffect, useState } from 'react';
import SectionWrapper from '../../../components/SectionWrapper/SectionWrapper';
import SectionHeader from '../../../components/SectionHeader/SectionHeader';
import NavigationArrow from '../../../components/Slick/NavigationArrow';
import { TArticle } from '../../../constants/types';
import Slider from 'react-slick';
import getRelativePath from '../../../utils/getRootDirectory';
import breakpoints from '../../../constants/breakpoints';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import BlogCard from '../../../components/BlogCard/BlogCard';
import blogs from '../../../assets/json/blogs.json';

const FeaturedArticles = () => {
  const [articles, setArticles] = useState<TArticle[] | []>([]);
  const { width } = useWindowDimensions();
  useEffect(() => {
    // fetch('')
    //   .then((res) => res.json())
    //   .then((data) => {
    //     setArticles(data.data);
    //   });
    const blogData: any = blogs.data;
    setArticles(blogData);
    return () => {};
  }, []);

  const settings = {
    dots: false,
    loading: true,
    infinite: false,
    speed: 500,
    slidesToShow: width > breakpoints.lg ? 1.3 : 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: breakpoints.lg + 1,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    prevArrow: (
      <NavigationArrow customClassName=" text-default-orange bg-white shadow-lg hover:bg-default-orange hover:text-white  xs:!left-auto !left-2 max-xs:bg-transparent max-xs:shadow-none  " />
    ),
    nextArrow: (
      <NavigationArrow customClassName=" text-default-orange bg-white shadow-lg hover:bg-default-orange hover:text-white  max-xs:bg-transparent max-xs:shadow-none max-xs:!-right-12" />
    ),
  };

  return (
    <SectionWrapper customClasses="bg-gray-50">
      <div className="mx-auto max-w-7xl ">
        <SectionHeader title="Featured Articles" />
        <div className="px-0 xs:px-16">
          <Slider {...settings} lazyLoad="ondemand" className="">
            {articles.map((article, index) => {
              return <BlogCard {...article} key={index}></BlogCard>;
            })}
          </Slider>
        </div>
      </div>
    </SectionWrapper>
  );
};

export default FeaturedArticles;
