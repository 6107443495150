import React, { useEffect, useState } from 'react';
import getRelativePath from '../../utils/getRootDirectory';
import { TArticle } from '../../constants/types';
import BlogCard from '../../components/BlogCard/BlogCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faSearch } from '@fortawesome/free-solid-svg-icons';
import SectionHeader from '../../components/SectionHeader/SectionHeader';
import { Link, useSearchParams } from 'react-router-dom';
import routes from '../../constants/routes';
import _ from 'lodash';
import Divider from '../../components/Divider/Divider';
interface RecentPostProps {
  articles: TArticle[];
}

const RecentPost: React.FC<RecentPostProps> = ({ articles }) => {
  return (
    <div>
      <h4 className="mb-5  font-semibold uppercase text-default-blue">Recent Posts</h4>
      <div className="grid grid-cols-1 divide-y">
        {articles.slice(0, 3).map((article, index) => {
          return (
            <Link
              to={`${routes.BLOGS}/${article.id}`}
              className="text-description cursor-pointer px-3 py-3 text-default-blue hover:bg-gray-200"
              key={index}
            >
              <p className="">{article.title}</p>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

const Blogs = () => {
  const [articles, setArticles] = useState<TArticle[] | []>([]);
  const [articlesCategory, setarticlesCategory] = useState<string[]>([]);
  const [searchParams, setSearchParams] = useSearchParams({ q: '', category: '' });
  const q = searchParams.get('q');
  const category = searchParams.get('category');
  useEffect(() => {
    fetch(`${getRelativePath('/db/json/blogs.json')}`)
      .then((res) => res.json())
      .then((data) => {
        const blogs: TArticle[] = data.data;
        setArticles(blogs);

        const categories = _.uniqBy(blogs, (item) => item.tags[0].name).map((item) => item.tags[0].name);
        setarticlesCategory(categories);
      });
    return () => {};
  }, []);

  const filteredData = articles.filter((item) => {
    const titleMatch = q ? item.title.toLowerCase().includes(q.toLowerCase()) : true;
    const tagMatch = category
      ? category === 'all'
        ? true
        : item.tags.some((tag) => tag.name.toLowerCase().includes(category.toLowerCase()))
      : true;
    return titleMatch && tagMatch;
  });

  return (
    <React.Fragment>
      <div className="mx-auto  max-w-7xl  px-5 pb-10 pt-36 lg:pt-48 xl:pt-36 2xl:px-0">
        <SectionHeader title="Blogs" wrapperClass="pt-10" />
        <div className="grid grid-cols-1 gap-y-5 lg:grid-cols-12 lg:gap-x-5 lg:gap-y-0">
          <div className=" order-2  grid gap-y-10 lg:order-first lg:col-span-8">
            {filteredData.length > 0 ? (
              filteredData.map((article, index) => {
                return <BlogCard {...article} key={index}></BlogCard>;
              })
            ) : (
              <p className="text-xl font-medium text-default-blue">Sorry, no blogs matching your search were found.</p>
            )}
          </div>
          <div className=" lg:col-span-4 ">
            <div className="flex flex-col gap-5">
              <div className="relative w-full">
                <input
                  id="q"
                  type="text"
                  value={q ?? ''}
                  className="block w-full rounded-lg border border-gray-300 bg-white p-2.5 text-sm hover:border-default-blue   focus:border-default-blue focus:outline-none focus:ring-2"
                  placeholder="Searach Blog"
                  onChange={(e) =>
                    setSearchParams(
                      (prev) => {
                        prev.set('q', e.target.value);
                        return prev;
                      },
                      { replace: true },
                    )
                  }
                />
                <button type="button" className="absolute inset-y-0 right-0 flex items-center pr-3 outline-none ">
                  <FontAwesomeIcon icon={faSearch} className=" text-gray-400" />
                </button>
              </div>
              <div>
                <select
                  id="categories"
                  value={category ?? 'all'}
                  className=" w-full rounded-lg border border-r-8 border-transparent  p-2.5 text-sm outline outline-1 outline-gray-300 hover:outline-default-blue focus:outline-default-blue focus:ring-2"
                  onChange={(e) =>
                    setSearchParams(
                      (prev) => {
                        prev.set('category', e.target.value);
                        return prev;
                      },
                      { replace: true },
                    )
                  }
                >
                  {/* block w-full rounded-lg border border-gray-300 bg-white p-2.5 text-sm text-gray-900 outline outline-neutral-700 focus:border-blue-500 focus:outline-none focus:ring-blue-500 */}
                  <option value="all">All Category</option>
                  {articlesCategory.map((cat, index) => {
                    return (
                      <option value={cat} key={index}>
                        {cat}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className="hidden pb-10 lg:inline">
                <RecentPost articles={articles} />
              </div>
            </div>
          </div>
          <div className=" order-last col-span-1 block lg:hidden">
            <RecentPost articles={articles} />
          </div>
        </div>
      </div>
      <Divider />
    </React.Fragment>
  );
};

export default Blogs;
