import React from 'react';
import SectionWrapper from '../../SectionWrapper/SectionWrapper';
import SectionHeader from '../../SectionHeader/SectionHeader';
import Slider from 'react-slick';
import SliderCard from '../../SliderCard/SliderCard';
import { ArrowLongRightIcon } from '@heroicons/react/24/outline';
import WhyUsBG from '../../../assets/bg/why-us-bg.svg';
import NavigationArrow from '../../Slick/NavigationArrow';
import breakpoints from '../../../constants/breakpoints';
import { TServices } from '../../../constants/types';
import LinkButton from '../../LinkButton/LinkButton';
import routes from '../../../constants/routes';
import { classNames } from '../../../helpers/common';

interface ServicesProps {
  serviceList: TServices[];
  headerText: string;
  trialRoute: string;
}
const Services: React.FC<ServicesProps> = ({ serviceList, headerText, trialRoute }) => {
  const settings = {
    dots: false,
    loading: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: breakpoints.xl + 1,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: breakpoints.md + 1,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    prevArrow: <NavigationArrow customClassName=" hover:text-gray-800 text-gray-500" />,
    nextArrow: <NavigationArrow customClassName="hover:text-gray-800 text-gray-500" />,
  };

  return (
    <SectionWrapper customClasses=" relative h-auto overflow-hidden ">
      <img
        alt="jivescribe why us"
        src={WhyUsBG}
        className="absolute left-1/2  top-1/2 w-full -translate-x-1/2 -translate-y-1/2 transform"
      />
      <div className="mx-auto max-w-7xl px-5 xs:px-10 2xl:px-0">
        <SectionHeader title={headerText} />
        <div className="static">
          <Slider {...settings} lazyLoad="ondemand" className=" ">
            {serviceList.map((slide, index) => {
              return <SliderCard {...slide} key={index}></SliderCard>;
            })}
          </Slider>
        </div>
        <div className="flex justify-center py-10">
          <LinkButton
            text="Start a Free Trial"
            color="secondary"
            variant="rounded"
            icon={<ArrowLongRightIcon className="h-6 xs:h-9" />}
            size="xl"
            customClass="btn-icon-move-right relative"
            to={`${routes.HASH.CONTACT_US}`}
            isRouter={false}
          />
        </div>
      </div>
    </SectionWrapper>
  );
};

export default Services;
