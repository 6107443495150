import React from 'react';
import { twMerge } from 'tailwind-merge';

interface RoundImageBGProps {
  customClassName: string;
  bgImage: string;
}

const RoundImageBG: React.FC<RoundImageBGProps> = ({ customClassName, bgImage }) => {
  return (
    <div
      className={twMerge(
        'bg-3 absolute left-[60%] top-1/2 z-10 h-[25rem] min-h-[30rem] w-[30rem] -translate-x-1/2 -translate-y-1/2 transform rounded-full',
        customClassName,
      )}
      style={{
        backgroundImage: `url(${bgImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPositionX: 'center',
      }}
    />
  );
};

export default RoundImageBG;
