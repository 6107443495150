import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faLocationDot, faEnvelope, faTty } from '@fortawesome/free-solid-svg-icons';
import IconFB from '../../assets/icons/social/facebook.png';
import IconIG from '../../assets/icons/social/instagram.png';
import IconLI from '../../assets/icons/social/linkedin.png';
import IconTT from '../../assets/icons/social/twitter.png';
import IconPS from '../../assets/icons/social/playstore.png';
import IconAS from '../../assets/icons/social/appstore.png';
import Logo from '../Logo/Logo';
import routes from '../../constants/routes';
import links from '../../constants/links';
import { faUsps } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
  return (
    <div className="">
      <div className=" h-auto max-w-7xl px-10 py-12 2xl:mx-auto 2xl:px-0">
        <div className="grid grid-cols-12 text-center xs:text-start">
          <div className="col-span-12 lg:col-span-5 xl:col-span-6">
            <div className="flex justify-center xs:justify-start">
              <Logo />
            </div>

            <div className="mt-10 flex flex-col gap-y-5 ">
              <div className="flex flex-col justify-center gap-5 font-medium tracking-tight">
                <div className="flex flex-col gap-5 text-center xs:flex-row xs:text-start">
                  <FontAwesomeIcon icon={faLocationDot} className="text-xl text-default-blue" />
                  <p className="w-auto xs:w-3/4">81-83 Campbell Street Surry Hills NSW 2010, Australia</p>
                </div>
                <div className="flex flex-col gap-5 text-center xs:flex-row xs:text-start">
                  <FontAwesomeIcon icon={faUsps} className="text-xl text-default-blue" />
                  <p className="w-auto xs:w-3/4">PO Box 1829 Macquarie Centre NSW 2113, Australia</p>
                </div>
                <div className="flex flex-col gap-5 text-center xs:flex-row xs:text-start">
                  <FontAwesomeIcon icon={faPhone} className="text-xl text-default-blue" />
                  <div>
                    <a href="tel:0272541107">(02) 7254 1107</a>
                  </div>
                </div>
                <div className="flex flex-col gap-5 text-center xs:flex-row xs:text-start">
                  <FontAwesomeIcon icon={faEnvelope} className="text-xl text-default-blue" />
                  <a href="mailto:sales@jivescribe.com">sales@jivescribe.com</a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-12 xs:col-span-4 lg:col-span-2">
            <div className="flex flex-col gap-5 pt-7 font-medium">
              <p className="mb-5 text-lg font-bold tracking-wider text-gray-400">About Us</p>
              <a href={routes.SERVICE_MT_PAGE} className="hover:underline">
                MT Services
              </a>
              <a href={routes.BLOGS} className="hover:underline">
                Blogs
              </a>
            </div>
          </div>
          <div className="col-span-12 xs:col-span-4 lg:col-span-2">
            <div className="flex flex-col gap-5 pt-7 font-medium">
              <p className="mb-5 text-lg font-bold tracking-wider text-gray-400">Login on Web</p>
              <div>
                <a href={links.WEB_LOGIN} target="_self" className="contained-rounded-button blue-button">
                  Login
                </a>
              </div>
            </div>
          </div>
          <div className="col-span-12 xs:col-span-4 lg:col-span-2">
            <div className="flex flex-col gap-5 pt-7 font-medium">
              <p className="mb-5 text-lg font-bold tracking-wider text-gray-400">Get the app</p>
              <div className="flex flex-col items-center gap-5 xs:items-start">
                <a href={links.PLAY_STORE} target="_blank" rel="noreferrer">
                  <img alt="jivescribe playstore" src={IconPS} className=" w-36" />
                </a>
                <a href={links.APP_STORE} target="_blank" rel="noreferrer">
                  <img alt="jivescribe appstore" src={IconAS} className=" w-36" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-20 flex items-center justify-between">
          <p className="text-sm">©2013 Jivescribe. All rights Reserved</p>
          <div className="flex cursor-pointer justify-center gap-5">
            <a href={links.SOCIAL.FB} target="_blank" rel="noreferrer">
              <img alt="jivescribe facebook meta" src={IconFB} className="h-9" />
            </a>
            <a href={links.SOCIAL.LI} target="_blank" rel="noreferrer">
              <img alt="jivescribe linkedin" src={IconLI} className="h-9" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
