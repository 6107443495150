import React from 'react';
import HeroBanner from './components/HeroBanner';
import Partners from './components/Partners';
import Faqs from './components/Faqs';
import Testimonials from './components/Testimonials';
import Services from './components/Services';
import WhoWeAre from './components/WhoWeAre';
import ContactUs from '../../components/CommonSections/ContactUs/ContactUs';
import CommonPartners from '../../components/CommonSections/Partners/Partners';
import FeaturedArticles from './components/FeaturedArticles';
import PageWrapper from '../../components/Layout/PageWrapper';
import Partnering from './components/Partnering';
import Divider from '../../components/Divider/Divider';

const LandingPage = () => {
  return (
    <PageWrapper>
      <HeroBanner />
      {/* <Partners /> Remove partners until further notice */}
      <Services />
      <WhoWeAre />
      {/* <Testimonials /> Remove testimonials until further notice*/}
      <Faqs />
      <FeaturedArticles />
      <ContactUs />
      <Partnering />
      <Divider />
      {/* <CommonPartners /> Remove partners until further notice */}
    </PageWrapper>
  );
};

export default LandingPage;
