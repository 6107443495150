const routes = {
  ROOT: '/',
  WHO_WE_ARE: '/who-we-are',
  SERVICE_MT_PAGE: '/medical-transcription-services',
  SERVICE_IT_PAGE: '/it-services',
  BLOGS: '/blogs',
  HASH: {
    CONTACT_US: '#contact-us-section',
    FAQS: '#faqs-section',
  },
};

export default routes;
