// import { faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import React, { ReactElement } from 'react';
import { CustomArrowProps } from 'react-slick';
import { twMerge } from 'tailwind-merge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { NavigationDirection } from '../../constants/enums';

type NewCustomArrowProps = CustomArrowProps & { customClassName?: string; icon?: ReactElement };

const NavigationArrow: React.FC<NewCustomArrowProps> = (props) => {
  const { className, style, onClick, customClassName, icon } = props;
  let direction: NavigationDirection = className?.includes('slick-prev') ? NavigationDirection.Left : NavigationDirection.Right;
  let position: object = direction === NavigationDirection.Left ? { left: -15 } : { right: -100 };
  let disabled = className?.includes('slick-disabled');

  return (
    <React.Fragment>
      {!disabled && (
        <button
          className={twMerge(
            'absolute top-1/2 z-[1] flex h-14 w-14 -translate-x-1/2 -translate-y-1/2 transform  items-center justify-center rounded-full p-1 text-black hover:bg-white hover:shadow-lg  disabled:cursor-not-allowed disabled:opacity-50 xs:h-20 xs:w-20 xs:p-5',
            customClassName ? customClassName : '',
          )}
          onClick={onClick}
          disabled={disabled}
          style={{ ...style, ...position }}
        >
          {icon ? (
            icon
          ) : direction === NavigationDirection.Left ? (
            <FontAwesomeIcon icon={faAngleLeft} className="text-5xl max-xs:text-4xl" />
          ) : (
            <FontAwesomeIcon icon={faAngleRight} className="text-5xl max-xs:text-4xl" />
          )}
        </button>
      )}
    </React.Fragment>
  );
};

export default NavigationArrow;
