import React from 'react';
import SectionHeader from '../../SectionHeader/SectionHeader';
import SectionWrapper from '../../../components/SectionWrapper/SectionWrapper';
import { TWhy } from '../../../constants/types';

interface WhyUsProps {
  title: string;
  list: TWhy[];
}

const WhyUsCard: React.FC<TWhy> = ({ icon, title, description }) => {
  return (
    <div className=" min-h-[16rem] rounded-3xl border border-white p-5 shadow-md hover:bg-white xs:p-10">
      <div className="flex flex-col gap-2 xs:flex-row 2xl:gap-5">
        <img alt={`jivescribe medical solutions ${title}`} src={icon} className="w-24 self-center xs:self-start" />
        <div className="flex flex-col gap-2 xl:gap-5">
          <h3 className=" font-semibold text-default-blue">{title}</h3>
          <p className="text-description font-medium text-black">{description}</p>
        </div>
      </div>
    </div>
  );
};

const WhyUs: React.FC<WhyUsProps> = ({ title, list }) => {
  return (
    <SectionWrapper customClasses=" h-auto bg-blue-50">
      <div className="mx-auto max-w-7xl ">
        <SectionHeader title={title} />
        <div className="grid grid-cols-1 gap-x-10  gap-y-5 lg:grid-cols-2">
          {list.map((data, index) => {
            return (
              <div className="col-span-1" key={index}>
                <WhyUsCard {...data} />
              </div>
            );
          })}
        </div>
      </div>
    </SectionWrapper>
  );
};

export default WhyUs;
