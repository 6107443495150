import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { TArticle } from '../../constants/types';
import getRelativePath from '../../utils/getRootDirectory';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import dayjs from 'dayjs';
import LinkButton from '../../components/LinkButton/LinkButton';
import { ArrowLongRightIcon } from '@heroicons/react/24/outline';
import routes from '../../constants/routes';
import { NavigationDirection } from '../../constants/enums';
import { replacableRootPath } from '../../constants/common';
import Divider from '../../components/Divider/Divider';
import blogs from '../../assets/json/blogs.json';
const BlogView = () => {
  const params = useParams();
  const [blog, setBlog] = useState<TArticle | undefined>(undefined);
  const [navBlog, setNavBlog] = useState<TArticle | undefined>(undefined);
  const [navDirection, setNavDirection] = useState<NavigationDirection>(NavigationDirection.Right);

  useEffect(() => {
    const fetchedData: any = blogs.data;

    const viewBlog = fetchedData.find((obj: any) => obj.id === params.id); // get the vlog
    const viewBlogIndex = fetchedData.findIndex((obj: any) => obj.id === params.id); // get the index of current vlog

    const prevBlog = fetchedData[viewBlogIndex - 1];
    const nextBlog = fetchedData[viewBlogIndex + 1];
    if (nextBlog) {
      setNavBlog(nextBlog);
      setNavDirection(NavigationDirection.Right);
    } else {
      setNavDirection(NavigationDirection.Left);
      setNavBlog(prevBlog);
    }

    setBlog(viewBlog);

    return () => {};
  }, [params.id]);

  return (
    <React.Fragment>
      <div className="pb-10">
        {blog && (
          <React.Fragment>
            <div className=" relative  h-[30rem] overflow-hidden  lg:h-[40rem] xl:h-screen">
              <div
                className="bg-zoom transtion flex  h-full items-center justify-center  pt-16 md:pt-0"
                style={{
                  backgroundImage: `url(${getRelativePath(blog.thumbnail)})`,
                  backgroundPositionY: 'top',
                }}
              >
                <div className="mx-auto flex max-w-3xl flex-col  gap-10 p-10 text-center xs:p-0">
                  <p className="wrap-balance text-2xl font-bold text-default-blue underline xs:text-4xl xs:!leading-[4rem]">{blog.title}</p>
                  <div className="flex flex-row justify-center gap-2 overflow-hidden ">
                    {blog.tags?.map((tag, index) => (
                      <p
                        key={index}
                        className=" whitespace-nowrap rounded-lg border border-gray-300  bg-white bg-opacity-80 px-5 py-2 text-sm text-default-orange"
                      >
                        {tag.name}
                      </p>
                    ))}
                  </div>
                  <div className="flex flex-row items-center justify-center gap-3">
                    <FontAwesomeIcon icon={faClock} size="sm" />
                    <h6 className=" text-sm">{dayjs(blog.publishDate).format('MMMM D, YYYY')}</h6>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="mx-auto  max-w-7xl px-10 py-20  2xl:px-0 [&_li]:ml-10 [&_li]:list-disc"
              dangerouslySetInnerHTML={{
                __html: blog.content.replace(replacableRootPath, process.env.REACT_APP_ROOT_PATH ?? ''),
              }}
            />
            {navBlog && (
              <div
                className="bg-zoom mx-auto flex  h-[20rem] max-w-7xl flex-col items-center justify-center gap-5"
                style={{
                  backgroundImage: `url(${getRelativePath(navBlog.thumbnail)})`,
                  backgroundPositionY: 'center',
                }}
              >
                <div className="mx-auto flex max-w-3xl flex-col  gap-10 p-10 text-center xs:p-0">
                  <p className="wrap-balance text-2xl font-bold text-default-blue underline xs:text-4xl xs:!leading-[4rem]">
                    {navBlog.title}
                  </p>
                </div>
                <div>
                  <LinkButton
                    text={navDirection === NavigationDirection.Right ? 'Next post' : 'Previous Post'}
                    color="secondary"
                    variant="rounded"
                    icon={navDirection === NavigationDirection.Right && <ArrowLongRightIcon className="h-6 xs:h-9" />}
                    customClass="btn-icon-move-right"
                    to={`${routes.BLOGS}/${navBlog.id}`}
                  />
                </div>
              </div>
            )}
          </React.Fragment>
        )}
      </div>
      <Divider />
    </React.Fragment>
  );
};

export default BlogView;
