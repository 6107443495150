import React from 'react';
import { TArticle } from '../../constants/types';
import getRelativePath from '../../utils/getRootDirectory';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import routes from '../../constants/routes';
import DOMPurify from 'dompurify';

const BlogCard: React.FC<TArticle> = (props) => {
  const { id, thumbnail, tags, title, content, publishDate } = props;
  return (
    <Link
      to={`${routes.BLOGS}/${id}`}
      className="group relative flex w-full max-w-[50rem] cursor-pointer flex-col rounded-xl bg-white bg-clip-border text-gray-700 shadow-md xs:flex-row "
    >
      <div className="relative m-0 shrink-0 overflow-hidden rounded-xl bg-white bg-clip-border text-gray-700  max-xs:rounded-b-none xs:w-2/6 xs:rounded-r-none">
        <img
          alt={title}
          src={getRelativePath(thumbnail)}
          className="h-full w-full object-cover transition-transform duration-500  group-hover:scale-125 max-xs:min-h-[13rem]"
          loading="lazy"
        />
      </div>
      <div className="relative flex flex-col gap-6 overflow-hidden p-5">
        <div className="flex flex-row gap-2 ">
          {tags?.map((tag, key) => (
            <p key={key} className=" whitespace-nowrap rounded-lg border border-gray-300  px-5 py-2 text-sm text-default-orange">
              {tag.name}
            </p>
          ))}
        </div>
        <div className="min-h-[8rem]">
          <h5 className="wrap-balance line-clamp-2 max-h-[3.6em] overflow-hidden text-ellipsis text-2xl font-bold  text-default-blue">
            {title}
          </h5>
          <div
            className="wrap-balance line-clamp-3 max-h-[5.5rem]  overflow-hidden text-ellipsis pt-4 font-semibold"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(content),
            }}
          ></div>
        </div>
        <div className="flex flex-row items-center gap-3">
          <FontAwesomeIcon icon={faClock} size="sm" />
          <h6 className="text-sm">{dayjs(publishDate).format('MMMM D, YYYY')}</h6>
        </div>
      </div>
    </Link>
  );
};

export default BlogCard;
