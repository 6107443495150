import React from 'react';
import SectionWrapper from '../../../components/SectionWrapper/SectionWrapper';
import SectionHeader from '../../../components/SectionHeader/SectionHeader';
import DoctorImage from '../../../assets/who-we-are/who-we-are-doctor.png';
const WhoWeAre = () => {
  return (
    <SectionWrapper customClasses=" h-auto bg-blue-50">
      <div className="mx-auto max-w-7xl ">
        <SectionHeader title="Who We Are" />
        <div className="grid grid-cols-1 gap-x-0 gap-y-10 xs:gap-x-3  lg:grid-cols-12 xl:grid-cols-2 xl:gap-x-0">
          <div className=" col-span-8 flex items-start xl:col-span-1">
            <div className="leading-relax  wrap-balance flex flex-col  gap-5 font-medium text-default-blue">
              <h3>
                Established in 2009, Jivescribe Medical Solutions leads in premium medical transcription and IT solutions. Utilizing
                advanced technology and a dedicated team, we pioneer in accuracy, service, affordability, and speed.
              </h3>
              <h3>
                <span className="text-underlined-orange">Proudly 100% Australian-owned</span>, we're committed to becoming your trusted
                partner, no matter the size of your practice.
              </h3>
            </div>
          </div>
          <div className="col-span-4 flex justify-center  xl:col-span-1">
            <img alt="Jivescribe medical services - who we are" loading="lazy" src={DoctorImage} className=" rounded-3xl" />
          </div>
        </div>
      </div>
    </SectionWrapper>
  );
};

export default WhoWeAre;
