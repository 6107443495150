import React, { useState, useRef } from 'react';
import { TFAQ } from '../../../constants/types';
import { PlusCircleIcon } from '@heroicons/react/24/solid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare } from '@fortawesome/free-regular-svg-icons';
import { faCirclePlus, faCircleMinus } from '@fortawesome/free-solid-svg-icons';
import SectionHeader from '../../../components/SectionHeader/SectionHeader';

const FaqCard: React.FC<TFAQ> = ({ question, answer }) => {
  const [isAnswerOpen, setIsAnswerOpen] = useState<boolean>(false);
  const [iconanimationCSS, setIconAnimationCSS] = useState<string>('');

  const toggleAnswer = () => {
    setIconAnimationCSS(isAnswerOpen ? 'animate-[spin_1s_reverse_1]' : 'animate-[spin_1s_normal_1]');
    setIsAnswerOpen(!isAnswerOpen);
  };
  return (
    <div className="w-full cursor-pointer rounded-xl border border-gray-300 " onClick={toggleAnswer}>
      <div className="flex  items-center justify-between gap-2 p-6">
        <p className="font-medium">{question}</p>
        <FontAwesomeIcon
          icon={isAnswerOpen ? faCircleMinus : faCirclePlus}
          className={` text-lg text-default-orange ${iconanimationCSS}`}
          onClick={toggleAnswer}
          onAnimationEnd={() => {
            setIconAnimationCSS('');
          }}
        />
      </div>
      <div className={`overflow-hidden  px-6 delay-0 duration-[1500ms] ${isAnswerOpen ? ' max-h-96' : 'max-h-0'}`}>
        <p className="pb-6">{answer}</p>
      </div>
    </div>
  );
};

const faqList: TFAQ[] = [
  {
    question: 'How much does the service cost?',
    answer: 'Please see the comparison page for indicative pricing, contact us for more information.',
  },
  {
    question: 'How quickly can Jivescribe get me up and running?',
    answer:
      'Log on to our portal can be done in around 30 mins , if we establish a dedicated connection usually 24 hours. First we need to discuss your requirements, and can even offer you a free trial of our services for 1 week. Simply contact us so we can get the ball rolling...',
  },
  {
    question: 'What equipment do I need?',
    answer:
      'Generally standard office and home computing equipment is suitable. You will need some kind of dictation device to record your audio files, and access to the internet through a web browser to upload your files.',
  },
  {
    question: 'Is installation & training provided?',
    answer: 'Yes – installation and training of your staff is provided as part of the Service Level Agreement.',
  },
  {
    question: 'How do I send Jivescribe my audio recordings?',
    answer: 'Login to our customer control panel, then click on the ‘Add New’ link under the ‘Dictations’ tab.',
  },
  {
    question: 'How do I retrieve my completed transcripts?',
    answer: 'Login to our customer control panel, then click on the ‘Transcriptions’ link.',
  },
  {
    question: 'What is the turnaround time and how do I know when my transcription is ready?',
    answer:
      'We offer 30 mins through to 24 hour turn around times, it depends on your requirements. You will be notified by email or SMS when your transcriptions are ready. You can also login to the customer control panel at anytime to see the progress of each report.',
  },
  {
    question: 'Is installation & training provided?',
    answer: 'Yes – installation and training of your staff is provided as part of the Service Level Agreement.',
  },
  {
    question: 'Can I trial Jivescribe in my current working environment?',
    answer: 'We generally provide a 1 week trial to test I.T connectivity, Quality, Accuracy and Turn Around Times of reports',
  },
  {
    question: 'Are the voice files and transcriptions secure & confidential?',
    answer:
      'Extremely secure, our system uses SSL (Secure Sockets Layer) and encryption technologies to ensure your data is safe. We are compliant with NPP (National Privacy Principles). Separate confidentiality agreements can be provided for your organisation.',
  },
];

const Faqs = () => {
  return (
    <div id="section-faqs" className="mx-auto max-w-7xl px-5 py-10 xs:px-10 xs:py-20 2xl:px-0">
      <div className=" text-2xl font-bold leading-loose text-default-blue xs:text-4xl ">
        <SectionHeader title="FREQUENTLY ASKED QUESTIONS" />
      </div>
      <div className="grid grid-cols-1 gap-x-10 gap-y-8 lg:grid-cols-2">
        {faqList.map((faq, index) => {
          return (
            <div className="col-span-1 " key={index}>
              <FaqCard {...faq} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Faqs;
