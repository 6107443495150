const breakpoints = {
  '3xl': 1600,
  '2xl': 1400,
  xl: 1280,
  lg: 1024,
  md: 768,
  sm: 640,
  xs: 600,
  mobile: 375,
};

export default breakpoints;
