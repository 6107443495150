import React from 'react';
import CommonHeroBanner from '../../../components/CommonSections/HeroBanner/HeroBanner';
import routes from '../../../constants/routes';
import BannerImage from '../../../assets/banner/MT illustration.png';

const HeroBanner = () => {
  return (
    <CommonHeroBanner
      title={
        <h1 className="font-semibold leading-normal tracking-wider text-default-blue 3xl:leading-loose">
          Unburden Your <span className=" border-b-2 border-default-orange">Workflow</span> & Spend More Time Where it Matters Most
        </h1>
      }
      subTitle={
        <h4 className="  font-medium leading-normal text-default-blue ">
          We understand the vital role accurate and timely medical documentation plays in patient care. Let us handle the medical writing,
          so you can spend more time with your patients.
        </h4>
      }
      linkButtonText="Start a Free Trial"
      linkButtonURL={`
      ${routes.HASH.CONTACT_US}`}
      imageCustomClass="huge-image-banner"
      imageURL={BannerImage}
    />
  );
};

export default HeroBanner;
