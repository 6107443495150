import React from 'react';
import SectionHeader from '../../../components/SectionHeader/SectionHeader';
import SectionWrapper from '../../../components/SectionWrapper/SectionWrapper';
import Logo from '../../../assets/logos/jives_name & logo white bg.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';

const Compare = () => {
  return (
    <SectionWrapper customClasses=" bg-gray-50">
      <div className="mx-auto max-w-7xl ">
        <SectionHeader title="How Do We Compare" />
        <div className="overflow-x-auto overflow-y-hidden rounded-3xl bg-transparent   xs:overflow-x-visible xs:overflow-y-visible ">
          <table className="mx-auto max-w-4xl  table-auto  border-separate border-spacing-0 overflow-x-auto rounded-3xl bg-transparent  py-5 text-default-blue shadow-lg xs:py-0">
            <thead className=" bg-white [&_tr>th:first-child]:rounded-tl-3xl [&_tr>th:last-child]:rounded-tr-3xl">
              <tr>
                <th className=" min-w-[12rem]  border px-4 py-2 "></th>
                <th className="pop-table-header relative flex h-[4.375rem] w-64 justify-center border border-x-0 bg-default-blue px-10">
                  <img src={Logo} alt="Jivescribe Medical Solutions" className="  z-20 h-20 w-64  rounded-xl opacity-0" />
                  <img
                    src={Logo}
                    alt="Jivescribe Medical Solutions"
                    className=" absolute -top-[0.7rem] z-20 h-[4.375rem] w-60  rounded-2xl "
                  />
                  <div className=" cover absolute top-[-20px] h-full w-full rounded-t-3xl bg-default-blue "></div>
                </th>
                <th className="w-40  border px-4 py-2">Current</th>
                <th className="w-40 border border-r-0 px-4 py-2">In-house</th>
              </tr>
            </thead>
            <tbody className="bg-white font-medium [&_tr:last-child>td:first-child]:rounded-bl-3xl [&_tr:last-child>td:last-child]:rounded-br-3xl  [&_tr:last-child>td]:border-b [&_tr>td:first-child]:border-x-0 [&_tr>td:first-child]:border-b [&_tr>td:first-child]:border-l  [&_tr>td:last-child]:border-x-0  [&_tr>td:last-child]:border-r  [&_tr>td:nth-child(2)]:border-x-0 [&_tr>td:nth-child(2)]:bg-default-blue  [&_tr>td:nth-child(2)]:text-white  [&_tr>td:nth-child(3)]:text-gray-400 [&_tr>td:nth-child(4)]:text-gray-400 [&_tr>td]:border-t-0">
              <tr className="">
                <td className="border px-4 py-2">Cost</td>
                <td className="border px-4 py-2  text-center">$0.35</td>
                <td className="border px-4 py-2  text-center">
                  <FontAwesomeIcon icon={faXmark} className="fa-xl " />
                </td>
                <td className="border px-4 py-2  text-center">$0.59 - $0.49</td>
              </tr>
              <tr className="">
                <td className="border px-4 py-2">Capacity</td>
                <td className="border px-4 py-2  text-center">
                  <FontAwesomeIcon icon={faCheck} className="fa-xl " />
                </td>
                <td className="border px-4 py-2  text-center">
                  <FontAwesomeIcon icon={faXmark} className="fa-xl " />
                </td>
                <td className="border px-4 py-2  text-center">
                  <FontAwesomeIcon icon={faXmark} className="fa-xl " />
                </td>
              </tr>
              <tr className="">
                <td className="border px-4 py-2">Flexibility</td>
                <td className="border px-4 py-2  text-center">
                  <FontAwesomeIcon icon={faCheck} className="fa-xl " />
                </td>
                <td className="border px-4 py-2  text-center">
                  <FontAwesomeIcon icon={faXmark} className="fa-xl " />
                </td>
                <td className="border px-4 py-2  text-center">
                  <FontAwesomeIcon icon={faXmark} className="fa-xl " />
                </td>
              </tr>
              <tr className="">
                <td className="border px-4 py-2">Potential Issues</td>
                <td className="border px-4 py-2  text-center">
                  <FontAwesomeIcon icon={faCheck} className="fa-xl" />
                </td>
                <td className="border px-4 py-2  text-center">
                  <FontAwesomeIcon icon={faXmark} className="fa-xl" />
                </td>
                <td className="border px-4 py-2  text-center">
                  <FontAwesomeIcon icon={faXmark} className="fa-xl" />
                </td>
              </tr>
              <tr className="">
                <td className="border px-4 py-2">
                  Cost Loading
                  <br />
                  (Public holidays / weekend)
                </td>
                <td className="border px-4 py-2  text-center">
                  <FontAwesomeIcon icon={faCheck} className="fa-xl " />
                </td>
                <td className="border px-4 py-2  text-center">
                  <FontAwesomeIcon icon={faXmark} className="fa-xl " />
                </td>
                <td className="border px-4 py-2  text-center">
                  <FontAwesomeIcon icon={faXmark} className="fa-xl " />
                </td>
              </tr>
              <tr className="">
                <td className="border px-4 py-2">
                  Automatic staff sickness
                  <br /> cover
                </td>
                <td className="border px-4 py-2  text-center">
                  <FontAwesomeIcon icon={faCheck} className="fa-xl " />
                </td>
                <td className="border px-4 py-2  text-center">
                  <FontAwesomeIcon icon={faXmark} className="fa-xl " />
                </td>
                <td className="border px-4 py-2  text-center">
                  <FontAwesomeIcon icon={faXmark} className="fa-xl " />
                </td>
              </tr>
              <tr className="">
                <td className="border px-4 py-2">
                  Automatic output increase <br /> capability
                </td>
                <td className="relative border py-2  text-center">
                  <FontAwesomeIcon icon={faCheck} className="fa-xl relative z-20" />
                  <div className=" absolute bottom-[-20px] left-0 h-full w-full rounded-b-3xl bg-default-blue"></div>
                </td>
                <td className="border px-4 py-2  text-center">
                  <FontAwesomeIcon icon={faXmark} className="fa-xl" />
                </td>
                <td className="border px-4 py-2  text-center">
                  <FontAwesomeIcon icon={faXmark} className="fa-xl " />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </SectionWrapper>
  );
};

export default Compare;
