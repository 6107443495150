import React from 'react';

import ContactUs from '../../components/CommonSections/ContactUs/ContactUs';
import Partners from '../../components/CommonSections/Partners/Partners';
import Compare from './components/Compare';
import HeroBanner from './components/HeroBanner';
import WhyUs from '../../components/CommonSections/WhyUs/WhyUs';

import IconDartboard from '../../assets/icons/why-choose-us/accuracy.png';
import IconDollar from '../../assets/icons/why-choose-us/dollar.png';
import IconGear from '../../assets/icons/why-choose-us/gears.png';
import IconShield from '../../assets/icons/why-choose-us/shield.png';
import Services from '../../components/CommonSections/Services/Services';

import IconExpertise from '../../assets/icons/services/heart.png';
import IconPrecision from '../../assets/icons/services/check.png';
import IconTAT from '../../assets/icons/services/turnaround.png';
import IconSecurity from '../../assets/icons/services/lock.png';
import IconSolution from '../../assets/icons/services/bulb.png';
import IconPricing from '../../assets/icons/services/dollar.png';
import { TServices } from '../../constants/types';
import HowItWorks from './components/HowItWorks/HowItWorks';
import PageWrapper from '../../components/Layout/PageWrapper';
import routes from '../../constants/routes';

const whyChooseUsData: TServices[] = [
  {
    icon: IconDartboard,
    title: 'Precision and Accuracy',
    description:
      'Our transcription service doesn`t just meet industry standards; we exceed them. With our two-level editing process (and the option to go up to three), we confidently deliver accuracy levels ranging from a solid 98% to a perfect 100%.',
  },
  {
    icon: IconGear,
    title: 'Seamless Integration',
    description:
      'Picture this – your medical records effortlessly flowing into your RIS/PACS and Practice Software. With Jivescribe, we make this integration a reality. We harmonize your data, ensuring a smooth operation between systems.',
  },
  {
    icon: IconShield,
    title: 'Top-Notch Security',
    description:
      'Your medical data is precious, and we treat it as such. We`ve implemented rigorous security measures to safeguard your information. Rest easy knowing your records are in the safest hands.',
  },
  {
    icon: IconDollar,
    title: 'Budget-Friendly Brilliance',
    description:
      'We`re not just accurate; we`re affordable too. Our pricing is designed with flexibility in mind, offering per-line and per-audio-minute options. Jivescribe`s services cost 55% to 67% less than in-house solutions.',
  },
];

const mtServices: TServices[] = [
  {
    title: 'Medical Expertise',
    description:
      "Our team understands the importance of healthcare documentation. We're not just IT experts; we're well-versed in medical terminology, ensuring accurate transcriptions that healthcare professionals can rely on.",
    icon: IconExpertise,
  },
  {
    title: 'Precision and Accuracy',
    description:
      'Our team of experienced medical transcriptionists are dedicated to delivering transcripts with precision and accuracy, meeting the highest industry standards.',
    icon: IconPrecision,
  },
  {
    title: 'Timely Turnaround',
    description:
      'We recognize the importance of timely documentation. With our efficient workflow and commitment to deadlines, you can trust us to deliver your transcriptions on time.',
    icon: IconTAT,
  },
  {
    title: 'Data Security',
    description:
      'We prioritize the security and confidentiality of your patient data. Our robust security measures ensure that sensitive medical information remains protected at all times.',
    icon: IconSecurity,
  },
  {
    title: 'Tailored Solutions',
    description:
      'Every healthcare practice is unique, and so are our transcription services. We offer customized solutions to meet your specific needs, whether you`re a small private practice or a large operation - we cater to all.',
    icon: IconSolution,
  },
  {
    title: 'Cost-Effective',
    description:
      'Quality shouldn`t come at a premium. We offer competitive pricing options, allowing you to access top-tier transcription services without breaking the bank.',
    icon: IconPricing,
  },
];

const MedicalTranscription = () => {
  return (
    <PageWrapper>
      <HeroBanner />
      <Services serviceList={mtServices} headerText="Medical Transcription" trialRoute={routes.SERVICE_MT_PAGE} />
      <WhyUs title="Why Choose Us" list={whyChooseUsData} />
      <HowItWorks />
      <Compare />
      <ContactUs />
      {/* <Partners /> Remove Partners until further notice */}
    </PageWrapper>
  );
};

export default MedicalTranscription;
