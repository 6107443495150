import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import '@fortawesome/fontawesome-svg-core/styles.css';
import { config as fontAwesomeConfig } from '@fortawesome/fontawesome-svg-core';
import LandingPage from './pages/LandingPage/LandingPage';
import Layout from './components/Layout/Layout';
import AboutUs from './pages/AboutUs/AboutUs';
import MedicalTranscription from './pages/MedicalTranscription/MedicalTranscription';
import routes from './constants/routes';
import IT from './pages/IT/IT';
import Blogs from './pages/Blogs/Blogs';
import BlogView from './pages/Blogs/BlogView';
import ScrollToTop from './components/Layout/ScrollToTop';
import PageNotFound from './pages/PageNotFound/PageNotFound';

fontAwesomeConfig.autoAddCss = false;
function App() {
  return (
    <Router>
      <ScrollToTop>
        <Routes>
          <Route path={routes.ROOT} element={<Layout />}>
            <Route index element={<LandingPage />} />
            <Route path={routes.WHO_WE_ARE} element={<AboutUs />} />
            <Route path={routes.SERVICE_MT_PAGE} element={<MedicalTranscription />} />
            {/* <Route path={routes.SERVICE_IT_PAGE} element={<IT />} /> */}
            <Route path={routes.BLOGS}>
              <Route path={routes.BLOGS} element={<Blogs />} />
              <Route path=":id" element={<BlogView />} />
            </Route>
            <Route path="*" element={<PageNotFound />} />
          </Route>
        </Routes>
      </ScrollToTop>
    </Router>
  );
}

export default App;
