import React from 'react';

const HeroBanner = () => {
  return (
    <div className="mx-auto flex h-[45rem] max-w-7xl items-center justify-center px-5 xs:px-10 lg:h-screen 2xl:px-0">
      <div className="flex flex-col gap-4">
        <h1 className="text-center font-semibold leading-loose text-default-blue ">
          <span className=" text-underlined-orange">Empowering</span> Healthcare,
        </h1>
        <h1 className="text-center font-semibold text-default-blue ">One Word at a Time</h1>
        <div className="flex justify-center">
          <h4 className="  w-10/12 text-center font-semibold italic text-default-blue  xs:leading-loose ">
            We are more than just a medical transcription firm – we are your trusted partner in healthcare documentation. Our journey began
            with a commitment to empower healthcare professionals, one word at a time.
          </h4>
        </div>
      </div>
    </div>
  );
};

export default HeroBanner;
