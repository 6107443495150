import React from 'react';
import { Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

interface LinkButtonProps {
  text: React.ReactNode | string;
  icon?: React.ReactNode | string;
  variant?: 'rounded';
  size?: 'xl' | 'base';
  customClass?: string;
  color?: 'primary' | 'secondary';
  to: string;
  isRouter?: boolean;
  target?: string;
}

const sizeStyles = {
  xl: 'text-lg xs:px-16 xs:py-3 xs:text-xl',
  base: 'xs:text-base',
};

const variantStyles = {
  rounded: 'contained-rounded-button',
};

const colorStyles = {
  primary: 'blue-button',
  secondary: 'orange-button',
};

const LinkButton: React.FC<LinkButtonProps> = (props) => {
  const { text, icon, variant, size, customClass, color, to, isRouter = true, target } = props;

  return (
    <React.Fragment>
      {isRouter ? (
        <div className=" flex justify-center xs:justify-start">
          <Link
            to={to}
            type="button"
            className={twMerge(
              variant ? variantStyles[variant] : '',
              color ? colorStyles[color] : '',
              size ? sizeStyles[size] : sizeStyles.base,
              customClass ? customClass : '',
            )}
          >
            <div className="flex items-center gap-4 ">
              {text}
              {icon && icon}
            </div>
          </Link>
        </div>
      ) : (
        <div className=" flex justify-center xs:justify-start">
          <a
            href={to}
            target={target ?? ''}
            type="button"
            className={twMerge(
              variant ? variantStyles[variant] : '',
              color ? colorStyles[color] : '',
              size ? sizeStyles[size] : sizeStyles.base,
              customClass ? customClass : '',
            )}
          >
            <div className="flex items-center  gap-2  xs:gap-4">
              {text}
              {icon && icon}
            </div>
          </a>
        </div>
      )}
    </React.Fragment>
  );
};

export default LinkButton;
