import React from 'react';
import { Outlet } from 'react-router';
import NavBar from '../NavBar/NavBar';
import Footer from '../Footer/Footer';

const Layout = () => {
  return (
    <div>
      <NavBar />
      <div className="">
        {/* pt-20 lg:pt-0 xl:pt-10 */}
        <Outlet />
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
