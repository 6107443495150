import React from 'react';
import DotImage from '../../assets/icons/section-dot.svg';
import { twMerge } from 'tailwind-merge';

interface SectionHeaderProps {
  title: string;
  titleTextClass?: string;
  wrapperClass?: string;
  dotClass?: string;
}

const SectionHeader: React.FC<SectionHeaderProps> = ({ title, titleTextClass, wrapperClass, dotClass }) => {
  return (
    <div className={twMerge('flex items-center justify-start  gap-2 pb-20 max-xs:py-5 xs:gap-4', wrapperClass ? wrapperClass : '')}>
      <img alt="jivescribe medical solutions - our story" src={DotImage} className={twMerge('w-4 xs:w-5', dotClass ? dotClass : '')} />
      <h2 className={twMerge('font-semibold uppercase text-default-blue', titleTextClass ? titleTextClass : '')}>{title}</h2>
    </div>
  );
};

export default SectionHeader;
