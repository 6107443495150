import React from 'react';
import { twMerge } from 'tailwind-merge';

interface ContentProps {
  customClassName: string;
  title: string;
  description: React.ReactNode | string;
  step: number;
}

const Content: React.FC<ContentProps> = ({ customClassName, step, title, description }) => {
  return (
    <div className={twMerge('flex flex-col items-center gap-2 gap-x-10 xs:gap-5 lg:items-start xl:flex-row', customClassName)}>
      <div className="flex h-12 w-12 items-center justify-center rounded-full bg-default-blue text-xl font-bold text-white xs:h-16 xs:w-16 xs:min-w-[4rem] xs:text-3xl">
        {step}
      </div>
      <div className="flex flex-col gap-2 text-center text-default-blue  xs:gap-5 lg:gap-10 lg:text-start">
        <h1 className="font-semibold">{title}</h1>
        <p className="text-lg font-medium xs:text-2xl">{description}</p>
      </div>
    </div>
  );
};

export default Content;
