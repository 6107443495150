import React from 'react';

interface SliderCardProps {
  title: string;
  description: string;
  icon: string;
}

const SliderCard: React.FC<SliderCardProps> = ({ icon, title, description }) => {
  return (
    <div className="whyus-card text-cente  my-6 flex h-[24rem] flex-col gap-y-5  rounded-3xl  border bg-transparent  bg-white bg-opacity-75 p-6 shadow-sm hover:shadow-lg xs:mx-2  xs:h-[28rem] xs:min-h-[28rem] xs:gap-y-10">
      <h3 className=" font-bold text-default-blue ">{title}</h3>
      <div className="flex h-16 self-start xs:h-24">
        <img alt="dev support" className="xs:w-28" src={icon} style={{ height: 'inherit' }}></img>
      </div>
      <p className="text-description wrap-balance font-medium text-black">{description}</p>
    </div>
  );
};

export default SliderCard;
