import React from 'react';
import WhyUsBG from '../../../assets/bg/why-us-bg.svg';
import ContactUsForm from './ContactUsForm';
import { ArrowLongRightIcon } from '@heroicons/react/24/outline';
import SectionWrapper from '../../SectionWrapper/SectionWrapper';
import LinkButton from '../../LinkButton/LinkButton';
import links from '../../../constants/links';

const ContactUs = () => {
  return (
    <SectionWrapper id="contact-us-section" customClasses=" relative bg-default-blue">
      <img
        alt="jivescribe why us"
        src={WhyUsBG}
        className=" absolute left-1/2  top-1/2 z-10 w-full -translate-x-1/2 -translate-y-1/2 transform "
      />
      <div className=" relative z-20 mx-auto max-w-7xl">
        <div className="grid grid-cols-1  lg:grid-cols-2">
          <div className=" col-span-1 flex flex-col  gap-10 pt-10 text-white xs:pt-20 xl:items-start">
            <div className="">
              <h1 className="mb-3 font-bold  tracking-wider max-xs:text-center">Ready for a free Trial?</h1>
              <p className=" text-description leading-relaxed max-xs:text-center">
                Try Jivescribe with our 1-week free trial.
                <br />
                Complete the form or contact our offices below.
              </p>
            </div>
            <div className="flex flex-col gap-1 max-xs:items-center">
              <h4 className="text-xl font-medium tracking-wider">Join Our Team</h4>
              <p className="text-description">Interested in transcription work?</p>
              <div>
                <LinkButton
                  text="Discover our job opportunities"
                  color="secondary"
                  variant="rounded"
                  icon={<ArrowLongRightIcon className="h-4 xs:h-7" />}
                  customClass="btn-icon-move-right relative"
                  isRouter={false}
                  to={links.SOCIAL.LI}
                  target="_blank"
                />
              </div>
            </div>
            <div className="max-xs:self-center  max-xs:text-center">
              <p className="text-description leading-loose">Questions? Email us at</p>
              <div className="w-fit rounded-lg bg-white p-3 text-default-orange">
                <a href="mailto:hello@jivescribe.com" className="font-medium underline">
                  hello@jivescribe.com
                </a>
              </div>
            </div>
          </div>
          <div className=" relative col-span-1 py-20 xl:px-10">
            <ContactUsForm />
          </div>
        </div>
      </div>
    </SectionWrapper>
  );
};

export default ContactUs;
