import React from 'react';
import SectionHeader from '../../../components/SectionHeader/SectionHeader';
import WhyUsBG from '../../../assets/bg/why-us-bg.svg';
import SectionWrapper from '../../../components/SectionWrapper/SectionWrapper';

export const OurMission = () => {
  return (
    <SectionWrapper customClasses=" h-auto bg-default-blue relative overflow-hidden">
      <img
        alt="jivescribe why us"
        src={WhyUsBG}
        className="absolute left-1/2  top-1/2  w-full -translate-x-1/2 -translate-y-1/2 transform"
      />

      <div className="mx-auto max-w-7xl px-5 xs:px-10 2xl:px-0">
        <SectionHeader title="Our Mission" titleTextClass="text-white" />
        <div>
          <p className="2xl:wrap-balance font-medium leading-relaxed text-white xs:text-2xl xs:leading-loose">
            Our mission is clear: empower healthcare providers with precise and timely medical transcripts. We value every word, diagnosis,
            and treatment plan, ensuring you have the tools for informed decisions and deliver the highest quality care to your patients.
            Yet, we don’t stop there, we're dedicated to streamlining workflows and enhancing task effectiveness to simplify your
            professional life
          </p>
        </div>
      </div>
    </SectionWrapper>
  );
};
