import React from 'react';
import ImageBG from '../../../assets/bg/partnership-bg.png';
import PartnershipImage from '../../../assets/partnership/Singcribe-Partnership.png';
import SingscribeImage from '../../../assets/partnership/sings name -logo.png';
import LinkButton from '../../../components/LinkButton/LinkButton';
import { ArrowLongRightIcon } from '@heroicons/react/24/outline';
import links from '../../../constants/links';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowRightLong } from '@fortawesome/free-solid-svg-icons';

const Partnering = () => {
  return (
    <div id="section-faqs" className=" mx-auto max-w-7xl px-5 py-10 xs:px-10 xs:py-20 2xl:px-0">
      <div className="relative grid grid-cols-1 rounded-3xl  shadow-[0px_0px_12px_#00000026] max-lg:gap-y-10 max-lg:py-10 lg:grid-cols-12">
        <img
          alt="jivescribe partnership"
          src={ImageBG}
          className="  absolute left-1/2 top-1/2 z-10 w-full -translate-x-1/2 -translate-y-1/2 transform"
        />
        <div className="relative z-20 lg:col-span-7">
          <img alt="jivescribe partnership" src={PartnershipImage} className="" />
        </div>
        <div className="relative z-20 self-center lg:col-span-5 ">
          <div className="flex flex-col items-center gap-y-5">
            <h1 className=" font-semibold text-default-blue">Partnering with</h1>
            <img alt="jivescribe partnership" src={SingscribeImage} className=" scale-75" />
            <LinkButton
              text="Learn More"
              color="secondary"
              variant="rounded"
              size="xl"
              icon={<FontAwesomeIcon icon={faArrowRightLong} />}
              customClass="btn-icon-move-right !py-4 !px-10 !font-semibold"
              to={links.SINGSCRIBE}
              isRouter={false}
              target="_blank"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Partnering;
