import React from 'react';
import getRelativePath from '../../../utils/getRootDirectory';
import LinkButton from '../../LinkButton/LinkButton';
import { ArrowLongRightIcon } from '@heroicons/react/24/outline';
import { twMerge } from 'tailwind-merge';

interface HeroBannerProps {
  title: React.ReactNode;
  subTitle: React.ReactNode;
  linkButtonText: string;
  linkButtonURL: string;
  imageURL: string;
  imageCustomClass?: string;
}

const HeroBanner: React.FC<HeroBannerProps> = ({ title, subTitle, linkButtonText, linkButtonURL, imageURL, imageCustomClass }) => {
  return (
    <div className=" lg:relative lg:overflow-hidden">
      <div className=" mx-auto h-auto  max-w-7xl pt-10 max-2xl:px-10 max-xs:px-5 xs:pt-20 xl:pt-0">
        <div className=" grid  h-auto grid-cols-1 max-lg:gap-y-5 max-xs:gap-y-10  lg:h-screen lg:grid-cols-12">
          <div className="z-20 col-span-6 flex self-center">
            <div className="flex h-full  flex-col items-center gap-y-10 text-center  max-xs:gap-y-5 lg:text-start xl:mt-0 xl:px-0">
              {title}
              {subTitle}

              <div className="w-full text-center lg:text-start">
                <LinkButton
                  text={linkButtonText}
                  color="secondary"
                  variant="rounded"
                  size="xl"
                  icon={<ArrowLongRightIcon className="h-6 xs:h-9" />}
                  customClass="btn-icon-move-right"
                  to={linkButtonURL}
                  isRouter={false}
                />
              </div>
            </div>
          </div>
          <div className="z-10 col-span-6 flex items-center justify-center">
            <div>
              <img
                src={imageURL}
                alt="Jivescribe medical solutions"
                className={twMerge(' xl:scale-75 2xl:scale-90 ', imageCustomClass ? imageCustomClass : '')}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroBanner;
