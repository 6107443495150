import React from 'react';
import HeroBanner from './components/HeroBanner';
import WhoWeareBG from '../../assets/bg/ellipse-bg-min.png';
import OurStory from './components/OurStory';
import { OurMission } from './components/OurMission';
import WhoWeAre from './components/WhoWeAre';
import WhyUs from '../../components/CommonSections/WhyUs/WhyUs';
import IconDartboard from '../../assets/icons/who-we-are/check_icon.png';
import IconClientCentric from '../../assets/icons/who-we-are/clock.png';
import IconTimeless from '../../assets/icons/who-we-are/users.png';
import IconDedicatedTeam from '../../assets/icons/who-we-are/client.png';

const whyChooseUsData = [
  {
    icon: IconDartboard,
    title: 'Precision and Accuracy',
    description:
      'Our transcription service doesn`t just meet industry standards; we exceed them. With our two-level editing process (and the option to go up to three), we confidently deliver accuracy levels ranging from a solid 98% to a perfect 100%.',
  },
  {
    icon: IconTimeless,
    title: 'Timeliness',
    description:
      'We understand the importance of timely documentation. Our team works tirelessly to meet your deadlines without compromising quality.',
  },
  {
    icon: IconDedicatedTeam,
    title: 'Dedicated Team',
    description:
      'Our team of experienced and certified transcriptionists are passionate about what they do, and it shows in the quality of our work.',
  },
  {
    icon: IconClientCentric,
    title: 'Client-Centric Approach',
    description: 'We believe in building lasting partnerships. Your needs and satisfaction are at the forefront of everything we do.',
  },
];
const AboutUs = () => {
  return (
    <React.Fragment>
      <div
        className=" h-auto"
        style={{
          backgroundImage: `url(${WhoWeareBG})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <HeroBanner />

        <OurStory />
      </div>
      <OurMission />

      <WhyUs title="WHAT WE'RE ALL ABOUT" list={whyChooseUsData} />
      {/* <WhoWeAre />  Removed this section until further notice*/}
    </React.Fragment>
  );
};

export default AboutUs;
