import React from 'react';

const Divider = () => {
  return (
    <div className=" mx-auto flex w-full max-w-7xl justify-center max-2xl:px-10">
      <div className="h-[1px] w-full rounded border-0 bg-gray-200" />
    </div>
  );
};

export default Divider;
