import React, { useEffect, useState } from 'react';
import SectionWrapper from '../../../../components/SectionWrapper/SectionWrapper';
import SectionHeader from '../../../../components/SectionHeader/SectionHeader';
import HowItWorksBg from '../../../../assets/bg/how-it-works-bg.svg';

import PhoneImage from '../../../../assets/how-it-works/phone.png';
import WebApp from '../../../../assets/how-it-works/web-app.png';
import Step1ImageBg from '../../../../assets/how-it-works/step-1-round-bg.jpg';
import Step2ImageBg from '../../../../assets/how-it-works/step-2-round-bg.jpg';
import Step3ImageBg from '../../../../assets/how-it-works/step-3-round-bg.jpg';
import UploadFileImage from '../../../../assets/how-it-works/upload-file.png';
import AudioWaveImage from '../../../../assets/how-it-works/audio-wave.png';
import LogoNameTransparentImage from '../../../../assets/logos/logo-name-transparent.png';
import PhoneLogoNameImage from '../../../../assets/how-it-works/phone-logo-name.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import LogoTransparent from '../../../../assets/logos/logo-transparent-sm.png';
import LogoNameTransparent from '../../../../assets/logos/name-transparent-sm.png';
import { NavigationDirection } from '../../../../constants/enums';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

import NavArrow from './components/NavArrow';
import Content from './components/Content';
import RoundImageBG from './components/RoundImageBG';
import { twMerge } from 'tailwind-merge';

const HowItWorks = () => {
  const [currentStep, setCurrentStep] = useState<number>(1);

  gsap.registerPlugin(ScrollTrigger);

  const animateStep1_content = () => {
    const tl = gsap
      .timeline()
      .fromTo(
        ['.gsap_content-1'],
        { opacity: 0, paddingTop: '20rem' },
        {
          opacity: 1,
          paddingTop: 0,
          duration: 0.5,
        },
      )
      .fromTo(
        '.gsap_visual-1 .file-1',
        { opacity: 0, paddingTop: '20rem' },
        {
          opacity: 1,
          paddingTop: 0,
          duration: 0.5,
        },
      )
      .fromTo(
        '.gsap_next',
        { opacity: 0 },
        {
          opacity: 1,
        },
      );
    return tl;
  };

  const animateStep1_visual = () => {
    const tl = gsap.timeline().fromTo(
      '.gsap_visual-1 .bg-1',
      {
        backgroundPositionX: '10%',
      },
      {
        backgroundPositionX: '50%',
        duration: 0.5,
      },
      0.5,
    );

    return tl;
  };

  useEffect(() => {
    gsap.set('.gsap_prev', { opacity: 0, disabled: true });

    ScrollTrigger.create({
      trigger: '.gsap_how-it-woks',
      start: 'start center',
      animation: animateStep1_content(),
    });

    ScrollTrigger.create({
      trigger: '.gsap_how-it-woks',
      start: 'start center',
      animation: animateStep1_visual(),
    });
  }, []);

  const handleClickNext = (direction: NavigationDirection) => {
    if (direction === NavigationDirection.Right && currentStep >= 3) return;
    if (direction === NavigationDirection.Left && currentStep <= 1) return;

    if (currentStep === 1 && direction === NavigationDirection.Right) {
      gsap.set(['.gsap_prev', '.gsap_next'], { opacity: 0, disabled: true });
      gsap
        .timeline()
        .fromTo(
          ['.gsap_content-1', '.gsap_visual-1 .file-1', '.gsap_visual-1 .bg-1'],
          { opacity: 1 },
          {
            opacity: 0,
            duration: 0.5,
          },
        )
        .fromTo(
          ['.gsap_content-2'],
          { opacity: 0, paddingTop: '20rem' },
          {
            opacity: 1,
            paddingTop: 0,
            duration: 0.5,
          },
        );

      gsap.timeline().fromTo('.gsap_visual-1 .phone-1', { opacity: 1 }, { opacity: 0, duration: 0.5 });
      gsap
        .timeline()
        .fromTo('.gsap_visual-2', { opacity: 0 }, { opacity: 1, duration: 0.5 })
        .fromTo('.gsap_visual-2 .phone-2 .gsap_logo', { opacity: 0, top: '-2rem' }, { opacity: 1, top: '1.5rem', duration: 0.5 });
      gsap.timeline().fromTo(['.gsap_visual-2 .phone-2', '.bg-2'], { opacity: 0 }, { opacity: 1, duration: 0.5 }).fromTo(
        '.bg-2',
        {
          backgroundPositionX: '50px',
        },
        {
          backgroundPositionX: '0',
          duration: 0.5,
        },
      );
      gsap.set('.audio-wave-container', { width: '15rem', delay: 1 });

      gsap
        .timeline()
        .fromTo('.audio-wave-cover', { width: '100%' }, { width: '66.6%', duration: 0.5 }, 1)
        .fromTo('.audio-wave-cover', { width: '66.6%' }, { width: '33.3%', duration: 0.5 })
        .fromTo('.audio-wave-cover', { width: '33.3%' }, { width: 0, duration: 0.5 });
      gsap.timeline().fromTo('.trans-text-title', { width: 0 }, { width: '100%', duration: 0.5 }, 1);
      gsap
        .timeline()
        .fromTo('.trans-text-1', { width: 0 }, { width: '100%', duration: 0.5 }, 1)
        .fromTo('.trans-text-2', { width: 0 }, { width: '100%', duration: 0.5 })
        .fromTo('.trans-text-3', { width: 0 }, { width: '100%', duration: 0.5 })
        .fromTo(['.gsap_prev', '.gsap_next'], { opacity: 0 }, { opacity: 1, duration: 0.5 })
        .set(['.gsap_prev', '.gsap_next'], { disabled: false });
    }
    if (currentStep === 2 && direction === NavigationDirection.Left) {
      gsap.set(['.gsap_prev', '.gsap_next'], { opacity: 0, disabled: true });
      gsap.set('.audio-wave-container', { width: '0' });
      gsap
        .timeline()
        .fromTo(
          ['.gsap_content-2'],
          { opacity: 1, paddingTop: '0' },
          {
            opacity: 0,
            paddingTop: '20rem',
            duration: 0.5,
          },
        )
        .fromTo(
          ['.gsap_visual-1 .file-1', '.gsap_visual-1 .bg-1'],
          { opacity: 0 },
          {
            opacity: 1,
            duration: 0.5,
          },
        );

      gsap.timeline().fromTo('.gsap_visual-1 .phone-1', { opacity: 0 }, { opacity: 1, duration: 0.5 });
      gsap
        .timeline()
        .fromTo('.gsap_visual-2', { opacity: 1 }, { opacity: 0, duration: 0.5 })
        .fromTo('.gsap_visual-2 .phone-2 .gsap_logo', { opacity: 1 }, { opacity: 0, duration: 0.5 });
      gsap
        .timeline()
        .fromTo('.gsap_visual-2 .phone-2', { opacity: 1 }, { opacity: 0, duration: 0.5 })
        .fromTo(
          '.bg-2',
          {
            opacity: '1',
          },
          {
            opacity: '0',
            duration: 0.5,
          },
        )
        .fromTo(['.gsap_next'], { opacity: 0 }, { opacity: 1 })
        .set(['.gsap_next'], { disabled: false });
      animateStep1_content();
      animateStep1_visual();
    }
    if (currentStep === 2 && direction === NavigationDirection.Right) {
      gsap.set(['.gsap_prev', '.gsap_next'], { opacity: 0, disabled: true });

      gsap.set('.audio-wave-container', { width: '0' });
      gsap.timeline().fromTo(
        ['.gsap_content-2'],
        { opacity: 1, paddingTop: '0' },
        {
          opacity: 0,
          duration: 0.5,
        },
      );

      gsap
        .timeline()
        .fromTo('.gsap_visual-2', { opacity: 1 }, { opacity: 0, duration: 0.5 })
        .fromTo('.gsap_visual-2 .phone-2 .gsap_logo', { opacity: 1 }, { opacity: 0, duration: 0.5 })
        .fromTo(
          '.gsap_visual-3 .bg-3',
          {
            backgroundPositionX: '50px',
          },
          {
            backgroundPositionX: '0',
            duration: 0.5,
          },
        );

      gsap.timeline().fromTo('.gsap_visual-2 .phone-2', { opacity: 1 }, { opacity: 0, duration: 0.5 }).fromTo(
        '.bg-2',
        {
          opacity: '1',
        },
        {
          opacity: '0',
          duration: 0.5,
        },
      );

      gsap
        .timeline()
        .fromTo(
          ['.gsap_visual-3', '.gsap_visual-3 .bg-1'],
          { opacity: 0 },
          {
            opacity: 1,
            duration: 0.5,
          },
        )
        .fromTo(
          ['.gsap_content-3'],
          { opacity: 0, paddingTop: '20rem' },
          {
            opacity: 1,
            paddingTop: 0,
            duration: 0.5,
          },
        );

      gsap
        .timeline()
        .fromTo(
          ['.gsap_visual-3 .phone-2'],
          { opacity: 0, left: '-30%', top: '-30%' },
          {
            opacity: 1,
            left: '0',
            top: '0',
            duration: 1,
          },
        )
        .fromTo(
          ['.gsap_visual-3 .logo-2'],
          { opacity: 0, paddingTop: '10rem' },
          {
            opacity: 1,
            paddingTop: '0',
            duration: 0.5,
          },
        );
      gsap
        .timeline()
        .fromTo(
          ['.gsap_visual-3 .phone-1'],
          { opacity: 0, right: '-30%', bottom: '-30%' },
          {
            opacity: 1,
            right: '0',
            bottom: '0',
            duration: 1,
          },
        )
        .fromTo(
          ['.gsap_visual-3 .logo-1'],
          { opacity: 0, paddingTop: '10rem' },
          {
            opacity: 1,
            paddingTop: '0',
            duration: 0.5,
          },
        )
        .fromTo(['.gsap_prev'], { opacity: 0 }, { opacity: 1 })
        .set(['.gsap_prev'], { disabled: false });
    }

    if (currentStep === 3 && direction === NavigationDirection.Left) {
      gsap.set(['.gsap_prev', '.gsap_next'], { opacity: 0, disabled: true });

      gsap
        .timeline()
        .fromTo(
          ['.gsap_content-3'],
          { opacity: 1, paddingTop: '0' },
          {
            opacity: 0,
            paddingTop: '20rem',
            duration: 0.5,
          },
        )
        .fromTo(
          ['.gsap_visual-3'],
          { opacity: 1 },
          {
            opacity: 0,
            duration: 0.5,
          },
        );

      gsap
        .timeline()
        .fromTo(
          ['.gsap_content-2'],
          { opacity: 0, paddingTop: '20rem' },
          {
            opacity: 1,
            paddingTop: 0,
            duration: 0.5,
          },
        )
        .fromTo('.gsap_visual-2', { opacity: 0 }, { opacity: 1, duration: 0.5 })
        .fromTo('.gsap_visual-2 .phone-2 .gsap_logo', { opacity: 0, top: '-2rem' }, { opacity: 1, top: '1.5rem', duration: 0.5 });
      gsap.timeline().fromTo(['.gsap_visual-2 .phone-2', '.bg-2'], { opacity: 0 }, { opacity: 1, duration: 0.5 }).fromTo(
        '.bg-2',
        {
          backgroundPositionX: '50px',
        },
        {
          backgroundPositionX: '0',
          duration: 0.5,
        },
      );
      gsap.set('.audio-wave-container', { width: '15rem', delay: 1 });

      gsap
        .timeline()
        .fromTo('.audio-wave-cover', { width: '100%' }, { width: '66.6%', duration: 0.5 }, 1)
        .fromTo('.audio-wave-cover', { width: '66.6%' }, { width: '33.3%', duration: 0.5 })
        .fromTo('.audio-wave-cover', { width: '33.3%' }, { width: 0, duration: 0.5 });
      gsap.timeline().fromTo('.trans-text-title', { width: 0 }, { width: '100%', duration: 0.5 }, 1);
      gsap
        .timeline()
        .fromTo('.trans-text-1', { width: 0 }, { width: '100%', duration: 0.5 }, 1)
        .fromTo('.trans-text-2', { width: 0 }, { width: '100%', duration: 0.5 })
        .fromTo('.trans-text-3', { width: 0 }, { width: '100%', duration: 0.5 })
        .fromTo(['.gsap_prev', '.gsap_next'], { opacity: 0 }, { opacity: 1, duration: 0.5 })
        .set(['.gsap_prev', '.gsap_next'], { disabled: false });
    }

    if (direction === NavigationDirection.Right && currentStep + 1 <= 3) setCurrentStep((prev) => prev + 1);
    if (direction === NavigationDirection.Left && currentStep - 1 >= 1) setCurrentStep((prev) => prev - 1);
  };

  return (
    <div
      className={twMerge(
        'xs:h-[63rem]  lg:h-[50rem] 2xl:h-screen',
        currentStep === 1 ? 'h-[48rem]' : currentStep === 2 ? 'h-[42rem]' : 'h-[54rem]',
      )}
      style={{
        backgroundImage: `url(${HowItWorksBg})`,
        backgroundRepeat: 'no-repeat',
        backgroundPositionX: 'center',
      }}
    >
      <SectionWrapper customClasses="bg-transparent">
        <div className="gsap_how-it-woks relative mx-auto max-w-7xl">
          <SectionHeader title="How to use jivescribe" titleTextClass="max-xs: whitespace-nowrap" />

          {/* Step 1 */}
          <div className="absolute grid w-full gap-x-5 gap-y-5 xs:gap-y-10 md:grid-cols-6 lg:grid-cols-12">
            <NavArrow
              direction={NavigationDirection.Left}
              icon={<FontAwesomeIcon icon={faAngleLeft} className="text-2xl xs:text-5xl" />}
              customClassName="gsap_prev 2xl:!left-5 xs:!left-10 z-40 !left-2"
              handleClick={() => handleClickNext(NavigationDirection.Left)}
            />
            <NavArrow
              direction={NavigationDirection.Right}
              icon={<FontAwesomeIcon icon={faAngleRight} className="text-2xl xs:text-5xl" />}
              customClassName="gsap_next 2xl:!-right-20 !-right-10 z-40"
              handleClick={() => handleClickNext(NavigationDirection.Right)}
            />

            <div className="col-span-6">
              <Content
                step={1}
                title="Upload your Audio"
                description="Record your patient notes, consultations, or reports."
                customClassName="gsap_content-1"
              />
            </div>
            <div className="relative col-span-6">
              <div className="gsap_visual-1 relative flex justify-center overflow-hidden lg:block lg:justify-normal">
                <div className="relative z-20 max-w-fit">
                  <img alt="how it works" src={PhoneImage} className="phone-1 relative h-[30rem]  xs:h-[35rem]" />
                  <img
                    alt="how it works"
                    src={UploadFileImage}
                    className="file-1  absolute left-1/2 top-1/2 z-10 w-44 -translate-x-1/2 -translate-y-1/2  transform xs:w-56"
                  />
                </div>
                <RoundImageBG customClassName="bg-1 hidden lg:block" bgImage={Step1ImageBg} />
              </div>
            </div>
          </div>

          {/* Step 2 */}
          <div className="absolute grid w-full gap-x-5  gap-y-10 md:grid-cols-6 lg:grid-cols-12">
            <div className="col-span-6">
              <Content
                step={2}
                title="Jivescribe transcribes"
                description="Our specialized team and cutting-edge software ensures 100% accuracy."
                customClassName="gsap_content-2 opacity-0 "
              />
            </div>
            <div className="relative col-span-6">
              <div className="gsap_visual-2 relative  flex  justify-center  opacity-0 lg:block lg:justify-normal">
                <div className="phone-2 relative z-20 max-w-fit opacity-0 xs:top-36">
                  <img alt="how it works" src={WebApp} className=" relative h-[22rem]" />
                  <img alt="how it works" src={LogoNameTransparentImage} className="gsap_logo absolute left-10 top-6 h-10  " />
                  <div className="absolute left-[10.5rem]  top-24 z-10 h-auto  w-60  -translate-x-1/2 -translate-y-1/2 transform">
                    <div className="trans-text-title w-0 overflow-hidden">
                      <p className="whitespace-nowrap text-sm font-semibold text-default-orange">Patient medical report</p>
                    </div>
                  </div>
                  <div
                    className="audio-wave-container absolute left-[10.5rem]  top-36 z-10 h-8  w-0  -translate-x-1/2 -translate-y-1/2 transform"
                    style={{
                      backgroundImage: `url(${AudioWaveImage})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'contain',
                    }}
                  >
                    <div className="audio-wave-cover absolute right-0 h-8 w-full bg-white"></div>
                  </div>
                  <div className="absolute left-[10.5rem]  top-56 z-10 h-auto  w-60  -translate-x-1/2 -translate-y-1/2 transform">
                    <div className=" whitespace-nowrap text-xs text-default-blue ">
                      <div className="trans-text-1 w-0 overflow-hidden">
                        <p>Name: Wilson Smith</p>
                      </div>
                      <div className="trans-text-2 w-0 overflow-hidden">
                        <p>Date of Birth: February 19, 1987</p>
                        <p>sex: Male</p>
                      </div>
                      <div className="trans-text-3 w-0 overflow-hidden">
                        <p className="whitespace-nowrap pt-3 font-semibold">Proof of Immunity to Measles, Mumps and Rubella (MMR)</p>
                      </div>
                    </div>
                  </div>
                </div>

                <RoundImageBG customClassName="bg-2 left-[60%] top-[90%] hidden lg:block" bgImage={Step2ImageBg} />
              </div>
            </div>
          </div>

          {/* Step 3 */}
          <div className="absolute grid w-full gap-x-5  gap-y-10 md:grid-cols-6 lg:grid-cols-12">
            <div className="col-span-6">
              <Content
                step={3}
                title="Access anytime, anywhere"
                description="Retrieve your transcriptions through our platform or through your registered email address."
                customClassName="gsap_content-3 opacity-0"
              />
            </div>
            <div className="relative col-span-6">
              <div className="gsap_visual-3 gsap_visual-1 o relative flex  justify-center overflow-hidden opacity-0 lg:block lg:justify-normal ">
                <div className="relative z-20 max-w-fit ">
                  <img alt="how it works" src={PhoneImage} className="phone-1 relative  h-[30rem]  xs:h-[35rem]" />
                  <img alt="how it works" src={PhoneLogoNameImage} className="phone-2 absolute top-0 h-[30rem]  xs:h-[35rem]" />
                  <img
                    alt="how it works"
                    src={LogoTransparent}
                    className="logo-1  absolute left-1/2 top-[40%] z-10 w-40 -translate-x-1/2  -translate-y-1/2 transform"
                  />
                  <img
                    alt="how it works"
                    src={LogoNameTransparent}
                    className="logo-2  absolute left-1/2 top-2/3 z-10  w-40 -translate-x-1/2  -translate-y-1/2 transform"
                  />
                </div>
                <RoundImageBG customClassName="bg-3 hidden lg:block" bgImage={Step3ImageBg} />
              </div>
            </div>
          </div>
        </div>
      </SectionWrapper>
    </div>
  );
};

export default HowItWorks;
