import React from 'react';
import SectionHeader from '../../../components/SectionHeader/SectionHeader';
import SectionWrapper from '../../../components/SectionWrapper/SectionWrapper';
import DoctorImage from '../../../assets/who-we-are/who-we-are-doctor.png';

const OurStory = () => {
  return (
    <SectionWrapper customClasses=" h-auto bg-transparent">
      <div className="mx-auto max-w-7xl ">
        <SectionHeader title="Our Story" />
        <div className="grid grid-cols-1 gap-x-0 gap-y-10 xs:gap-x-3  lg:grid-cols-12 xl:grid-cols-2 xl:gap-x-0">
          <div className=" col-span-8 flex items-center xl:col-span-1">
            <div className=" flex flex-col gap-5 text-2xl font-medium leading-relaxed text-default-blue">
              <p>
                Founded in 2009, Jivescribe Medical Solutions started with a simple idea: to help medical professionals with reliable
                transcription services.
              </p>
              <p>
                Over the years, we've grown, but our focus remains the same – offering quality and personalized solutions no matter the size
                of your practice.
              </p>
            </div>
          </div>
          <div className="col-span-4 flex justify-center lg:justify-end xl:col-span-1 xl:justify-center ">
            <img alt="Jivescribe medical services - who we are" loading="lazy" src={DoctorImage} className=" rounded-3xl" />
          </div>
        </div>
      </div>
    </SectionWrapper>
  );
};

export default OurStory;
