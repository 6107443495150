import React from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router';

interface ScrollToTopProps {
  children?: any;
}

const ScrollToTop: React.FC<ScrollToTopProps> = ({ children }) => {
  const location = useLocation();

  const scrollToSection = () => {
    let targetElement = document.getElementById(location.hash.slice(1));
    if (targetElement) {
      targetElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }
  };

  useEffect(() => {
    if (location.hash) {
      const timer = setTimeout(() => {
        scrollToSection();
      }, 300);

      return () => clearTimeout(timer);
    }
  }, []);

  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    } else {
      document.documentElement.scrollTo(0, 0);
    }

    return function cleanup() {
      document.documentElement.scrollTo(0, 0);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);
  return children;
};

export default ScrollToTop;
