import React, { ChangeEvent, FormEvent, useState } from 'react';
import emailjs from '@emailjs/browser';

interface FormData {
  name: string;
  email: string;
  message: string;
  number: string;
  phone: string;
}

const formDataInitialState: FormData = {
  name: '',
  email: '',
  message: '',
  number: '',
  phone: '',
};

const ContactUsForm = () => {
  const [formData, setFormData] = useState<FormData>(formDataInitialState);
  const [error, setError] = useState<string>('');
  const [success, setSucces] = useState<string>('');
  const [isSending, setIsSending] = useState<boolean>(false);

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError('');
    setSucces('');

    if (!formData.number) {
      try {
        setIsSending(true);
        await emailjs
          .send(
            process.env.REACT_APP_EMAILJS_SERVICE_ID || '',
            process.env.REACT_APP_EMAILJS_TEMPLATE_ID || '',
            { ...formData },
            process.env.REACT_APP_EMAILJS_PUBLIC_KEY,
          )
          .then(
            function () {
              setSucces('Your Inquiry has been sent! Thank you for contacting us.');
              setFormData(formDataInitialState);
            },
            function () {
              setError('Something went wrong. Please try again later!');
            },
          );
        setIsSending(false);
      } catch (err: any) {
        setError('Something went wrong. Please try again later!');
      }
    } else {
      setFormData(formDataInitialState);
    }
  };
  return (
    <div className=" rounded-2xl bg-white px-5 py-10 shadow-md xs:px-12">
      <form className=" h-full " onSubmit={handleSubmit}>
        <p className="sx:text-3xl text-center text-2xl font-bold tracking-wider text-default-blue">Contact form</p>
        <div className="mb-6 pt-5">
          <input
            className="focus:border-default-blue-500 w-full rounded-lg border border-gray-400 px-3 py-2 focus:outline-none"
            placeholder="Full Name *"
            type="text"
            name="name"
            id="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-6">
          <input
            className="focus:border-default-blue-500 w-full rounded-lg border border-gray-400 px-3 py-2 focus:outline-none"
            placeholder="Email Address *"
            type="email"
            name="email"
            id="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-6">
          <input
            className="focus:border-default-blue-500 w-full rounded-lg border border-gray-400 px-3 py-2 focus:outline-none"
            placeholder="Phone *"
            type="number"
            name="phone"
            id="phone"
            value={formData.phone}
            onChange={handleChange}
          />
        </div>
        <div className="mb-6 hidden">
          <input
            className="focus:border-default-blue-500 w-full rounded-lg border border-gray-400 px-3 py-2 focus:outline-none"
            type="text"
            name="number"
            id="number"
            value={formData.number}
            onChange={handleChange}
          />
        </div>
        <div className="mb-6">
          <textarea
            className="focus:border-default-blue-500 w-full rounded-lg border border-gray-400 px-3 py-2 focus:outline-none"
            placeholder="Message *"
            name="message"
            id="message"
            rows={5}
            value={formData.message}
            onChange={handleChange}
            required
          ></textarea>
        </div>

        {error && (
          <div className="mb-4 rounded-lg bg-red-50 p-4 text-sm text-red-800" role="alert">
            {error}
          </div>
        )}
        {success && (
          <div className="mb-4 rounded-lg bg-green-50 p-4 text-sm text-green-800" role="alert">
            {success}
          </div>
        )}
        <p className="text-description wrap-balance mb-6 text-center font-medium">
          <span>or just email us directly at</span>
          <span>
            <a href="mailto:sales@jivescribe.com" className="pl-1 underline">
              hello@jivescribe.com
            </a>
          </span>
        </p>
        <div className="flex justify-center">
          <button type="submit" className="contained-rounded-button orange-button w-1/2 uppercase " disabled={isSending}>
            {isSending ? 'Sending...' : 'Send'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default ContactUsForm;
