import React from 'react';

import CommonHeroBanner from '../../../components/CommonSections/HeroBanner/HeroBanner';
import routes from '../../../constants/routes';
import BannerImage from '../../../assets/banner/JMS illustration-min.png';

const HeroBanner = () => {
  return (
    <CommonHeroBanner
      title={
        <h1 className="font-semibold leading-normal tracking-wider  text-default-blue 3xl:leading-loose">
          <span className="text-underlined-orange">
            Transcription Services <br />
          </span>
          for Medical Professionals Worldwide
        </h1>
      }
      subTitle={
        <h4 className=" wrap-balance font-medium leading-normal text-default-blue">
          Digitally enhance healthcare documentation efficiency and optimize your daily workflow with Jivescribe.
        </h4>
      }
      linkButtonText="Start a Free Trial"
      linkButtonURL={`${routes.ROOT}${routes.HASH.CONTACT_US}`}
      imageURL={BannerImage}
    />
  );
};

export default HeroBanner;
