import React, { ClassAttributes } from 'react';
import { twMerge } from 'tailwind-merge';

interface SectionWrapperProps {
  children: React.ReactNode;
  customClasses?: string;
  id?: string;
}
const SectionWrapper: React.FC<SectionWrapperProps> = ({ children, customClasses, ...rest }) => {
  return (
    <div className={twMerge('h-auto bg-white px-5 py-20 max-xs:py-5', customClasses ? customClasses : '')} {...rest}>
      {children}
    </div>
  );
};

export default SectionWrapper;
