import React from 'react';
import { Disclosure } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { useLocation } from 'react-router-dom';
import ButtonSubMenu from './components/ButtonSubMenu';

import Logo from '../Logo/Logo';
import routes from '../../constants/routes';
import { twMerge } from 'tailwind-merge';
import links from '../../constants/links';

const MenuButtons = () => {
  return (
    <div className="flex flex-col justify-center  xs:gap-3 lg:flex-row">
      <div className="max-xs:h-12">
        <a href={links.WEB_LOGIN} target="_self" type="submit" className="contained-rounded-button blue-button">
          Login
        </a>
      </div>
      <div>
        <a href={`${routes.ROOT}${routes.HASH.CONTACT_US}`} type="submit" className="contained-rounded-button blue-button">
          Contact Support
        </a>
      </div>
    </div>
  );
};

const NavBar = () => {
  const location = useLocation();

  const currentRoute = location.pathname;

  const navigation = [
    { name: 'Medical Transcription', href: routes.SERVICE_MT_PAGE },
    { name: 'About Us', href: routes.WHO_WE_ARE },
    // { name: 'FAQ', href: `${routes.ROOT}#section-faqs` },
    { name: 'Blogs', href: routes.BLOGS },
  ];

  return (
    <div
      className={twMerge(
        'absolute z-50 m-auto flex w-full justify-center max-xs:px-5  xs:py-5 ',
        currentRoute.includes(`${routes.BLOGS}/`) ? '' : 'shadow-md',
      )}
    >
      <Disclosure as="nav" className="relative w-full max-w-7xl">
        {({ open }) => (
          <>
            <div className="relative mx-auto max-w-7xl sm:px-6 lg:px-8">
              <div className="relative flex h-24 items-center justify-between ">
                <div className="flex  h-full w-full items-center justify-start sm:justify-start">
                  <a href="/">
                    <Logo />
                  </a>

                  <div className="hidden h-full w-full lg:inline">
                    <div className="flex h-full items-center justify-end gap-x-5">
                      {/* <ButtonSubMenu /> */}
                      {navigation.map((item, index) => (
                        <div className="relative flex h-full min-w-[4rem] items-center text-center" key={index}>
                          {currentRoute !== item.href ? (
                            <a
                              className="text-dark-green flex h-full w-full items-center justify-center text-base hover:text-default-orange"
                              href={item.href}
                            >
                              {item.name}
                            </a>
                          ) : (
                            <p className="text-default-orange" key={index}>
                              {item.name}
                            </p>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="absolute inset-y-0 right-0 flex items-center gap-x-5 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                  <div className="hidden gap-x-5 lg:flex">
                    <MenuButtons />
                  </div>

                  <div className="inset-y-0 left-0 flex items-center lg:hidden">
                    {/* Mobile menu button*/}
                    <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-default-blue hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                      ) : (
                        <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                      )}
                    </Disclosure.Button>
                  </div>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="absolute w-full bg-white pb-10 text-center shadow-sm lg:hidden">
              <div className="space-y-1 px-2 pb-3 pt-2">
                {/* <ButtonSubMenu /> */}
                {navigation.map((item, index) =>
                  currentRoute !== item.href ? (
                    <Disclosure.Button
                      key={index}
                      as="a"
                      href={item.href}
                      className="block rounded-md px-3 py-2 text-base text-black hover:text-default-orange "
                      aria-current="page"
                    >
                      {item.name}
                    </Disclosure.Button>
                  ) : (
                    <p className="text-default-orange" key={index}>
                      {' '}
                      {item.name}
                    </p>
                  ),
                )}
                <div className=" flex w-auto flex-col gap-y-3">
                  <MenuButtons />
                </div>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
};

export default NavBar;
