import React from 'react';
import { NavigationDirection } from '../../../../../constants/enums';
import { twMerge } from 'tailwind-merge';

interface NavArrowProps {
  direction: NavigationDirection;
  icon: React.ReactNode;
  customClassName?: string;
  handleClick?: () => void;
  disabled?: boolean;
}

const NavArrow: React.FC<NavArrowProps> = ({ direction, icon, customClassName, handleClick, disabled = false }) => {
  let position: object = direction === NavigationDirection.Left ? { left: -15 } : { right: -100 };
  return (
    <button
      className={twMerge(
        `absolute top-1/2 z-[1] flex h-14 w-14 -translate-x-1/2 -translate-y-1/2 transform  items-center justify-center rounded-full bg-white p-2 text-default-orange shadow-lg disabled:cursor-auto  disabled:opacity-50 xs:h-20 xs:w-20 xs:p-5`,
        customClassName ? customClassName : '',
      )}
      style={{ ...position }}
      onClick={handleClick}
      disabled={disabled}
    >
      {icon}
    </button>
  );
};

export default NavArrow;
