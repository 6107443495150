import React from 'react';
import LogoIcon from '../../assets/logos/logo-transparent-sm.png';
import NameIcon from '../../assets/logos/name-transparent-sm.png';

const Logo = () => {
  return (
    <div className="flex gap-3">
      <img alt="Jivescribe Medical Solutions logo" src={LogoIcon} className=" h-12 xs:h-14" />
      <img alt="Jivescribe Medical Solutions Name" src={NameIcon} className=" h-12 xs:h-14" />
    </div>
  );
};

export default Logo;
