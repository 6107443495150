const links = {
  WEB_LOGIN: 'https://jivescribe.com.au/doctor/sign-in',
  PLAY_STORE: 'https://play.google.com/store/apps/details?id=com.jivescribe.cmsg&pli=1',
  APP_STORE: 'https://apps.apple.com/vn/app/jivescribe/id1557197979',
  SINGSCRIBE: 'https://singscribe.com/',
  SOCIAL: {
    FB: 'https://www.facebook.com/profile.php?id=100070269368666&mibextid=ZbWKwL',
    LI: 'https://www.linkedin.com/company/jivescribe-pty-ltd/',
  },
};

export default links;
